import { ArrowDownOutlined, ArrowUpOutlined, RightOutlined } from '@ant-design/icons';
import { message, Modal, Popover } from 'antd';
import { renderModal } from 'egenie-common';
import type { BaseData, ColumnType, ValueAndLabelData } from 'egenie-utils';
import { BatchReport, EgGridModel, NormalProgramme, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import shortid from 'shortid';
import { api, getPlatformTypeIcon, SHELVES_PLATFORM } from '../../utils';
import ValidPermissionModalStore from '../shelvesNew/validPermissionModal/store';
import DownloadPicPackageStore from '../styleDetail/component/downloadPicPackage/downloadPicPackageStore';
import PublishGoodsStore from '../styleDetail/component/publishGoods/publishGoodsStore';
import NewStategyStore from './newStrategy/newStrategyStore';
import styles from './productAnalysis.less';

interface IFilterObject {
  brandName?: undefined | string;
  categoryStyleName?: undefined | string;
  goodsCategoryId?: undefined | number[];
  goodsCode?: undefined | string;
  goodsName?: undefined | string;
  lifeCycle?: undefined | string | number;
  maxCostPrice?: undefined | number;
  maxProductPrice?: undefined | number;
  minCostPrice?: undefined | number;
  minProductPrice?: undefined | number;
  platformId?: undefined | string;
  putAwayTime?: undefined | string;
  saleCycle?: undefined | string | number;
  salesStatus?: undefined | string;
  strategyStatus?: undefined | string;
  styleId?: undefined | string;
  vendorShopId?: undefined | string;
  page?: string | number;
  pageSize?: string | number;
  sidx?: string;
  sord?: string;
}

function RightHeader({ name }) {
  return (
    <div
      className={styles.rowlr}
      style={{ textAlign: 'right' }}
    >
      {name}
    </div>
  );
}

// 详情页面
const detailDict = {
  '1': (detailId) => {
    return `https://item.taobao.com/item.htm?ft=t&id=${detailId}`;
  },
  '-100': (detailId) => {
    return `https://detail.tmall.com/item.htm?id=${detailId}`;
  },
  '21': (detailId) => {
    return `https://haohuo.jinritemai.com/views/product/detail?id=${detailId}`;
  },
};
export default class ProductAnalysisStore {
  @observable public history = {
    page: 1,
    pageSize: 20,
    sord: '',
    sidx: '',
  };

  @observable public lifeCycleVisible = false;

  @observable public publishGoodsStore = new PublishGoodsStore({ parent: this });

  @observable public downloadPicPackageStore = new DownloadPicPackageStore({ parent: this });

  @observable public newStategyStore = new NewStategyStore({ parent: this });

  @observable public validPermissionModalStore = new ValidPermissionModalStore({ parent: this });

  @observable public defaultTab = '';

  @observable public lifeCycleList = [];

  @observable public lifeCycleParamVisible = false;

  @observable public lifeCycleParam = {
    growthRate: null, // 增长率
    salesThresholdOne: null,
    salesThresholdTwo: null,
    salesThresholdThree: null,
    newDays: null,
  };

  @observable public lifeCycleParamLoading = false;

  @observable public rateList = [];

  @observable public freshTime = '';

  @observable public condition: IFilterObject = {}; // 保存查询条件的值

  @observable public showPlatformGoods = false; // 复制平台商品显隐

  @observable private currentGoodsId: number; // 当前复制的平台商品ID

  @observable public copyGoods = {
    gmsStyleId: null, // 系统款式id
    type: 'copy', // copy 复制平台商品，create 创建平台商品（默认）
    mainPicUrl: '', // 图片url
    name: '', // 款式或商品名称
    price: null, // 供货价或售价
    gmsStyleNo: '', // 系统款式编码
  };

  @action public onChange = (key: string, value: number): void => {
    if (key === 'shopId') {
      this.shopId = value;

      // 重新刷新页面
      if (value) {
        this.getLifeCycle();
      }
      this.getRateList();
      this.onQuery();
    }
  };

  @observable public dictList = { shopList: []};

  public normalProgramme: NormalProgramme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        field: 'salesStatus',
        label: '销售状态',
        type: 'select',
        onChangeCallback: (value: string) => {
          this.normalProgramme.filterItems.updateFilterItem([
            {
              field: 'lifeCycle',
              value: undefined,
              disabled: value != undefined ? value !== '1' : false,
            },
          ]);
        },
      },
      {
        field: 'lifeCycle',
        label: '生命周期',
        type: 'select',
        onChangeCallback: (value: string) => {
          this.normalProgramme.filterItems.updateFilterItem([
            {
              field: 'salesStatus',
              value: '1',
            },
          ]);
        },
      },
      {
        field: 'goodsCategoryId',
        label: '类目',
        type: 'cascader',
        isParamList: true,
        showSearch: false,
        loadData: async(selectedOptions: ValueAndLabelData) => {
          const targetOption = selectedOptions[selectedOptions.length - 1];
          if (targetOption.children) {
            return;
          }

          function dfs(data: ValueAndLabelData, id: string): ValueAndLabelData[0] | null {
            if (!Array.isArray(data)) {
              return null;
            }

            for (let i = 0; i < data.length; i++) {
              if (data[i].value === id) {
                return data[i];
              }

              const subResult = dfs(data[i].children, id);
              if (subResult) {
                return subResult;
              }
            }

            return null;
          }

          const oldData = this.normalProgramme.filterItems.getFilterItem('goodsCategoryId').data;
          const targetItem = dfs(oldData, targetOption.value);
          if (!targetItem) {
            return;
          }

          targetOption.loading = true;
          try {
            // @ts-ignore
            targetItem.children = await this.getCategory(targetOption.value);
          } finally {
            targetItem.loading = false;
          }
        },
      },
      {
        field: 'vendorShopId',
        label: '档口',
        type: 'select',
      },
      {
        field: 'costPrice',
        label: '供货价(￥)',
        type: 'inputNumberGroup',
        placeholder: [
          '最低价',
          '最高价',
        ],
        minParamsField: 'minCostPrice',
        maxParamsField: 'maxCostPrice',
      },
      {
        field: 'productPrice',
        label: '售价(￥)',
        type: 'inputNumberGroup',
        placeholder: [
          '最低价',
          '最高价',
        ],
        minParamsField: 'minProductPrice',
        maxParamsField: 'maxProductPrice',
      },
      {
        field: 'strategyStatus',
        label: '策略完成情况',
        type: 'select',
      },
      {
        field: 'putAwayTime',
        label: '最近上架时间',
        type: 'select',
        placeholder: '上架时间',
      },
      {
        field: 'goodsCode',
        label: '系统款式编码',
        type: 'input',
      },
      {
        field: 'categoryStyleName',
        label: '风格',
        type: 'input',
      },
      {
        field: 'brandName',
        label: '品牌',
        type: 'input',
      },
      {
        field: 'platformId',
        label: '平台商品ID',
        type: 'input',
      },
      {
        field: 'goodsName',
        label: '商品名称',
        type: 'input',
      },
      {
        field: 'chooserId',
        label: '选款人',
        type: 'select',
      },
    ],
    handleSearch: () => this.onQuery(),
  });

  @observable public shopId = 0;

  @observable public searchShopFlag = true;

  @action public initPage = async(): Promise<void> => {
    this.getDict();
    if (this.shopId) {
      this.getLifeCycle();
    }
    this.getRateList();
    this.onQuery();
    const goodsCategoryIdData = await this.getCategory('0');
    this.normalProgramme.filterItems.addDict({ goodsCategoryId: goodsCategoryIdData });
  };

  @action public getDict = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.getDict,
      method: 'post',
      data: {
        commonDictList: [
          'salesStatusList',
          'lifeCycleList',
          'vendorShopList',
          'shopList',
          'strategyFinishStatus',
          'dateList',
          'strategyList',
          'chooserList',
        ],
      },
    });
    this.dictList.shopList = res.data?.shopList || [];

    this.normalProgramme.filterItems.addDict({
      salesStatus: (res.data?.salesStatusList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
      lifeCycle: (res.data?.lifeCycleList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
      vendorShopId: (res.data?.vendorShopList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
      strategyStatus: (res.data?.strategyFinishStatus || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
      putAwayTime: (res.data?.dateList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
      chooserId: (res.data?.chooserList || []).map((item) => ({
        value: item.key,
        label: item.value,
      })),
    });

    if (!this.shopId && this.searchShopFlag && res.data.shopList.length) {
      this.getLifeCycle();
    }
  };

  @action public getCategory = async(parentCategoryId: string): Promise<ValueAndLabelData> => {
    const res = await request<BaseData<any>>({
      url: api.getCategoryList,
      method: 'post',
      data: {
        parentCategoryId,
        categoryType: 25,
      },
    });

    return (res.data || []).map((el) => ({
      label: el.categoryIdName,
      value: `${el.categoryId}`,
      isLeaf: el.leaf,
    }));
  };

  @action public getLifeCycle = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.getLifeCycle,
      method: 'post',
      data: { shopId: this.shopId },
    });

    this.lifeCycleList = res.data.map((el) => {
      el.salesStatus = String(el.salesStatus);
      return el;
    });
  };

  @action public getRateList = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.getGoodsSalesStat,
      method: 'post',
      data: { shopId: this.shopId },
    });

    this.freshTime = res.data.freshTime;
    this.rateList = res.data.list;
  };

  @action public onQuery = () => {
    this.condition = this.normalProgramme.filterItems.params;
    this.gridModel.clearToOriginal();
    Object.assign(this.history, { page: 1 });
    return this.onSearch();
  };

  @action public onSearch = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.getGoodsList,
      method: 'post',
      data: {
        ...this.condition,
        ...this.history,
        goodsCategoryId: this.condition.goodsCategoryId ? this.condition.goodsCategoryId[1] : this.condition.goodsCategoryId,
        shopId: this.shopId,
      },
    });

    this.gridModel.loading = false;
    this.gridModel.rows = res.data.list;
    this.gridModel.total = res.data.totalCount;
    this.gridModel.selectedIds = new Set();
  };

  @action public onPageChange = (page: number | string, pageSize: number | string): void => {
    Object.assign(this.history, {
      page,
      pageSize,
    });
    this.onSearch();
  };

  @action public onSort = (params): void => {
    console.log('sort', params);
    Object.assign(this.history, params);
    this.onSearch();
  };

  public colums: ColumnType = [
    {
      key: 'operation',
      name: '操作',
      width: 120,
      formatter: ({ row }) => {
        const {
          strategyStatus,
          gmsGoodsId,
          salesStatus,
          posGoodsId,
          shopPlatformType,
        } = row;
        return (
          <div className={`${styles.flex} ${styles.flexColumn} ${styles.flexCenter} ${styles.lh1} ${styles.h100}`}>
            {/* { strategyStatus ? (
              <a onClick={() => this.editStrategy(row, 'new')}>
                分配策略
              </a>
            ) : (
              <a onClick={() => this.editStrategy(row, 'edit')}>
                编辑策略
              </a>
            ) } */}
            <a onClick={() => this.downloadPicPackageStore.onModalShow(gmsGoodsId, '')}>
              下载图片包
            </a>
            <a
              className={styles.mt10}
              onClick={() => this.publishGoodsStore.onFullModalShow('', '', gmsGoodsId, null, '复制平台商品', 2, '', posGoodsId)}
            >
              复制平台商品
            </a>
            {/* 上架入口 */}
            {
              ((salesStatus === 0 || salesStatus === 2) && (SHELVES_PLATFORM.find((v) => v.shopPlatformType === shopPlatformType))) ? (
                <a
                  className={styles.mt10}
                  onClick={() => {
                    this.onPutAway(row);
                  }}
                >
                  上架
                </a>
              ) : (
                <Popover content={(
                  <div>
                    {(SHELVES_PLATFORM.find((v) => v.shopPlatformType === shopPlatformType)) ? '上架中' : '功能开发中，敬请期待'}
                  </div>
                )}
                >
                  <a
                    className={styles.mt10}
                    style={{ color: '#ccc' }}
                  >
                    上架
                  </a>

                </Popover>
              )
            }
            {
              salesStatus === 1 && (
                <a
                  className={styles.mt10}
                  onClick={() => this.onSoldOut([gmsGoodsId])}
                >
                  下架
                </a>
              )
            }
            {/* {
              salesStatus === 0 ? (
                <Popover content={(
                  <div>
                    功能开发中，敬请期待
                  </div>
                )}
                >
                  <a
                    className={styles.mt10}
                    style={{ color: '#ccc' }}
                  >
                    上架
                  </a>

                </Popover>
              ) : (
                <a
                  className={styles.mt10}
                  onClick={() => this.onSoldOut([gmsGoodsId])}
                >
                  下架
                </a>
              )
            } */}
          </div>
        );
      },
    },
    {
      key: 'info',
      name: '平台商品信息',
      width: 274,
      resizable: true,
      formatter: ({ row }) => {
        const {
          smallPicUrl,
          goodsName,
          goodsCategoryName,
          brandName,
          shopName,
          shopPlatformType,
        } = row;

        // const imgUrl = require('../../../assets/images/platform/shop_icon_1.png').default;
        return (
          <div className={`${styles.flex} ${styles.flexColumn} ${styles.lh1} ${styles.h100} ${styles.infoWrap}`}>
            <div className={styles.flex}>
              <img
                className={styles.img}
                src={smallPicUrl}
              />
              <div className={`${styles.flex} ${styles.flexColumn} ${styles.ml10} ${styles.flexBetween} ${styles.clrate} ${styles.productContent}`}>
                <div className={`${styles.font400} ${styles.font12} ${styles.productName} `}>
                  {goodsName}
                </div>
                <div className={`${styles.clgrey} ${styles.font400} ${styles.font12} ${styles.flex} `}>
                  <span>
                    {goodsCategoryName}
                  </span>
                  <div
                    className={`${styles.oneRow} ${styles.ml20}`}
                  >
                    品牌：
                    {brandName}
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.flex} ${styles.flexBetween} ${styles.mt10}`}>
              <div className={`${styles.clgrey} ${styles.font400} ${styles.font12} ${styles.oneRow} ${styles.flex}`}>
                <img
                  alt=""
                  height="12px"
                  src={getPlatformTypeIcon(shopPlatformType)}
                  style={{ marginRight: '4px' }}
                  width="12px"
                />
                店铺：
                <div
                  className={styles.shopName}
                  title={shopName}
                >
                  {shopName}
                </div>
              </div>
              <div className={styles.ml20}>
                {
                  row.platformId ? (
                    <a
                      className={styles.ml20}
                      onClick={() => {
                        top.open(detailDict[shopPlatformType](row.platformId));
                      }}
                    >
                      商品详情
                      <RightOutlined/>
                    </a>
                  ) : null
                }
              </div>
            </div>
          </div>
        );
      },
    },
    {
      key: 'sourceFromName',
      name: '来源',
      width: 100,
    },
    {
      key: 'chooser',
      name: '选款人',
      width: 100,
    },
    {
      key: 'platformId',
      name: '平台商品ID',
      width: 115,
      resizable: true,
    },
    {
      key: 'salesStatusName',
      name: '销售状态',
      width: 150,
      formatter: ({ row }) => {
        return (
          <div className={styles.salesStatusWrap}>
            <div className={styles.salesStatusName}>
              {row.salesStatusName}
            </div>
            <Popover
              content={(
                <span style={{ color: '#f2270a' }}>
                  {row.reason}
                </span>
              )}
            >
              {row.salesStatus === 0 && row.reason && (
                <div className={styles.failReason}>
                  上架失败：
                  {' '}
                  {
                    row.reason
                  }
                </div>
              )}
            </Popover>
          </div>
        );
      },
    },
    {
      key: 'strategyStatus',
      name: '商品策略',
      width: 120,
      formatter: ({ row }) => {
        const {
          strategyStatus,
          goodsStrategyList,
        } = row;
        let unfinishStrategy = [];
        if (Array.isArray(goodsStrategyList)) {
          unfinishStrategy = goodsStrategyList.filter((item) => {
            return item.status === 2;
          });
        }

        // console.log('unfinishStrategy', unfinishStrategy);

        const content = Array.isArray(unfinishStrategy) && unfinishStrategy.map((el) => {
          return (
            <div
              className={styles.strategyPop}
              key={el.gmsGoodsStrategyId}
            >
              <div>
                {el.name}
              </div>
            </div>
          );
        });
        return (
          <div className={styles.strategyWrapper}>

            {
              unfinishStrategy.length <= 1 ? (
                unfinishStrategy.map((el, index) => {
                  return (
                    <div
                      className={styles.strategyItem}
                      key={el.gmsGoodsStrategyId}
                    >
                      {el.name}
                    </div>
                  );
                })
              ) : (
                <Popover
                  arrowPointAtCenter
                  content={content}
                  placement="rightBottom"
                >
                  {
                    unfinishStrategy && unfinishStrategy.map((el, index) => {
                      if (index > 1) {
                        return null;
                      }
                      return (
                        <div
                          className={styles.strategyItem}
                          key={el.gmsGoodsStrategyId}
                        >
                          {el.name}
                        </div>
                      );
                    })
                  }
                  {unfinishStrategy.length > 2 && (
                    <div className={styles.ellipse}>
                      ...
                    </div>
                  )}
                </Popover>
              )
            }
            {strategyStatus ? (
              <a
                className={styles.strategyEdit}
                onClick={() => this.editStrategy(row, 'new')}
              >
                分配策略
              </a>
            ) : (
              <a
                className={styles.strategyEdit}
                onClick={() => this.editStrategy(row, 'edit')}
              >
                编辑
              </a>
            )}

          </div>
        );
      },
    },
    {
      key: 'goodsCode',
      name: '系统款式编码',
      width: 120,
    },
    {
      key: 'vendorTenantName',
      name: '档口',
      width: 120,
    },
    {
      key: 'costPrice',
      name: <RightHeader name="供货价"/>,
      width: 120,
      formatter: ({ row }) => (
        <div style={{ textAlign: 'right' }}>
          {row.costPrice}
        </div>
      ),
    },
    {
      key: 'salePrice',
      name: <RightHeader name="售价"/>,
      width: 120,
      formatter: ({ row }) => (
        <div style={{ textAlign: 'right' }}>
          {row.salePrice}
        </div>
      ),
    },
    {
      key: 'stockNum',
      name: <RightHeader name="库存"/>,
      width: 120,
      formatter: ({ row }) => (
        <div style={{ textAlign: 'right' }}>
          {row.stockNum}
        </div>
      ),
    },
    {
      key: 'salesNum',
      name: <RightHeader name="近7日销量"/>,
      width: 120,
      formatter: ({ row }) => {
        const { goodsSalesVo = {}} = row;
        const {
          salesNum,
          salesNumPercent,
          salesNumMoM,
          salesFlag,
        } = goodsSalesVo || {};
        return (
          <div className={`${styles.flex} ${styles.flexColumn} ${styles.lh1} ${styles.h100} ${styles.flexCenter} ${styles.flexEnd}`}>
            <div>
              {salesNum}
            </div>
            <div className={`${styles.mt8} ${styles.clgrey} ${styles.font400}`}>
              占比
              {salesNumPercent}
              %
            </div>
            <div className={`${styles.mt8} ${styles.clgrey} ${styles.font400}`}>
              环比
              <span className={`${salesFlag ? styles.clred : styles.clgreen} ${styles.font500} ${styles.ml4}`}>
                {salesFlag ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                {salesNumMoM}
                %
              </span>
            </div>

          </div>
        );
      },
    },
    {
      key: 'business',
      name: <RightHeader name="近7日销售额"/>,
      width: 120,
      formatter: ({ row }) => {
        const { goodsSalesVo = {}} = row;
        const {
          business,
          businessPercent,
          businessMoM,
          businessFlag,
        } = goodsSalesVo || {};
        return (
          <div className={`${styles.flex} ${styles.flexColumn} ${styles.lh1} ${styles.h100} ${styles.flexCenter} ${styles.flexEnd}`}>
            <div>
              {business}
            </div>
            <div className={`${styles.mt8} ${styles.clgrey} ${styles.font400}`}>
              占比
              {businessPercent}
              %
            </div>
            <div className={`${styles.mt8} ${styles.clgrey} ${styles.font400}`}>
              环比
              <span className={`${businessFlag ? styles.clred : styles.clgreen} ${styles.font500} ${styles.ml4}`}>
                {businessFlag ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                {businessMoM}
                %
              </span>
            </div>

          </div>
        );
      },
    },
    {
      key: 'createTime',
      name: '添加时间',
      width: 120,
      sortable: true,
    },
    {
      key: 'putAwayTime',
      name: '最近上架时间',
      width: 120,
      sortable: true,
    },

  ];

  public gridModel = new EgGridModel({
    columns: this.colums,
    rows: [],
    rowHeight: 107,
    primaryKeyField: 'gmsGoodsId',
    edgStyle: {
      height: '100%',
      flexDirection: 'column',
    },
    sortByLocal: false,
    pageSize: 20,
    showCheckBox: true,
    api: {
      onPageChange: this.onPageChange,
      onSort: this.onSort,
      onShowSizeChange: this.onPageChange,
      onRefresh: this.onSearch,
    },
  });

  // 打开生命周期介绍
  @action public onLifeCycleShow = (defaultTab: string, e): void => {
    this.defaultTab = defaultTab;
    this.lifeCycleVisible = true;
    e.stopPropagation();
  };

  // 关闭生命周期介绍
  @action public onLifeCycleClose = (): void => {
    this.lifeCycleVisible = false;
  };

  // 打开生命周期配置
  @action public onLifeCycleParamShow = async(): Promise<void> => {
    this.lifeCycleParamVisible = true;
    this.lifeCycleVisible = false;

    const res = await request<BaseData<any>>({
      url: api.getLifeCycleParam,
      method: 'post',
      data: { shopId: this.shopId },
    });
    const {
      growthRate,
      salesThresholdOne,
      salesThresholdTwo,
      salesThresholdThree,
      newDays,
    } = res.data;
    Object.assign(this.lifeCycleParam, {
      growthRate,
      salesThresholdOne,
      salesThresholdTwo,
      salesThresholdThree,
      newDays,
    });
  };

  // 生命周期配置恢复默认值
  @action public defaultLifeCycleParam = async(): Promise<void> => {
    const res = await request<BaseData<any>>({
      url: api.lifeCycleDefaultConfig,
      method: 'post',
      data: { shopId: this.shopId },
    });
    const {
      growthRate,
      salesThresholdOne,
      salesThresholdTwo,
      salesThresholdThree,
      newDays,
    } = res.data;
    Object.assign(this.lifeCycleParam, {
      growthRate,
      salesThresholdOne,
      salesThresholdTwo,
      salesThresholdThree,
      newDays,
    });
  };

  // 生命周期配置保存
  @action public saveLifeCycleParam = (): void => {
    this.lifeCycleParamLoading = true;
    request<BaseData<any>>({
      url: api.saveLifeCycleParam,
      method: 'post',
      data: {
        shopId: this.shopId,
        ...this.lifeCycleParam,
      },
    })
      .then((res) => {
        this.lifeCycleParamLoading = false;
        message.success('保存成功');
        this.closeLifeCycleParam();
      })
      .finally(() => {
        this.lifeCycleParamLoading = false;
      });
  };

  // 关闭生命周期配置
  @action public closeLifeCycleParam = (): void => {
    this.lifeCycleParamVisible = false;
    Object.assign(this.lifeCycleParam, {
      growthRate: undefined, // 增长率
      salesThresholdOne: undefined,
      salesThresholdTwo: undefined,
      salesThresholdThree: undefined,
      newDays: undefined,
    });
  };

  // 修改生命周期配置
  @action public changeLifeCycleParam = (key: string, value: number): void => {
    if (key === 'newDays' && value < 6) {
      message.warning('上新天数不得小于6天');
      return;
    }
    this.lifeCycleParam[key] = value;
  };

  // 分配策略
  @action public newStrategy = (): void => {
    const rows = this.gridModel.rows;
    const ids = Array.from(this.gridModel.selectedIds);
    if (!ids.length) {
      message.warning('请选择商品');
      return;
    }
    const productList = [];
    for (let i = 0; i < ids.length; i++) {
      const el = ids[i];
      const temp = rows.find((item) => item.gmsGoodsId === el);
      if (temp) {
        const {
          goodsName,
          gmsGoodsId,
          smallPicUrl,
        } = temp;
        productList.push({
          goodsName,
          gmsGoodsId,
          smallPicUrl,
        });
      }
    }
    this.newStategyStore.onShow(productList, 'new', this.onSearch);
  };

  // 编辑策略
  @action public editStrategy = (row, flag): void => {
    const productList = [];
    const {
      goodsName,
      gmsGoodsId,
      smallPicUrl,
    } = row;
    productList.push({
      goodsName,
      gmsGoodsId,
      smallPicUrl,
    });
    this.newStategyStore.onShow(productList, flag, this.onSearch);
  };

  // 复制平台商品
  @action public copyPlatformGood = (row): void => {
    const {
      smallPicUrl,
      goodsName,
      salePrice,
      goodsCode,
      gmsGoodsId,
    } = row;
    this.showPlatformGoods = true;
    this.currentGoodsId = gmsGoodsId;
    this.copyGoods = {
      gmsStyleId: null, // 系统款式id
      type: 'copy', // copy 复制平台商品，create 创建平台商品（默认）
      mainPicUrl: smallPicUrl, // 图片url
      name: goodsName, // 款式或商品名称
      price: salePrice, // 供货价或售价
      gmsStyleNo: goodsCode, // 系统款式编码
    };
    console.log('copyPlatformGood', this.copyGoods);
  };

  // 关闭复制平台商品弹框
  @action public closePlatformGood = () => {
    this.showPlatformGoods = false;
    this.currentGoodsId = null;
    this.copyGoods = {
      gmsStyleId: null, // 系统款式id
      type: 'copy', // copy 复制平台商品，create 创建平台商品（默认）
      mainPicUrl: '', // 图片url
      name: '', // 款式或商品名称
      price: null, // 供货价或售价
      gmsStyleNo: '', // 系统款式编码
    };
  };

  // 保存复制平台商品弹框
  @action public savePlatformGood = async(data): Promise<void> => {
    console.log('savePlatformGood', data);
    const postData = {
      gmsGoodsId: this.currentGoodsId,
      pcCreateGoodsShopAndStrategyToList: data,
    };
    await request<BaseData<any>>({
      url: api.copyGoods,
      method: 'post',
      data: postData,
    });
    message.success('复制成功');
    this.closePlatformGood();
    this.onSearch();
  };

  @action public lifeCycleClick = (status: number): void => {
    this.normalProgramme.filterItems.updateFilterItem([
      {
        field: 'salesStatus',
        value: '1',
      },
      {
        field: 'lifeCycle',
        value: `${status}`,
        disabled: false,
      },
    ]);
    this.onQuery();
  };

  @action public onPutAway = (row) => {
    this.validPermissionModalStore.shopId = row.shopId;
    const shopId = row.shopId;
    const gmsGoodsId = row.gmsGoodsId;
    const pageId = shortid.generate();
    request<BaseData<any>>({
      url: api.validePermission,
      method: 'POST',
      data: { shopId },
    })
      .then((res) => {
        if (res.data) {
          const platformType = res.data.platformType;
          const item = SHELVES_PLATFORM.find((v) => v.shopPlatformType === platformType);
          const url = item?.url || '';
          window.top.egenie.openTab(`${url}?gmsGoodsId=${gmsGoodsId}&shopId=${shopId}&platformType=${platformType}&pageId=${pageId}`, pageId, '上架');
        } else {
          this.validPermissionModalStore.visiblePermission = true;
        }
      })
      .catch(() => {
        this.validPermissionModalStore.visiblePermission = true;
      });
  };

  @action public onPutAwayCallback = (gmsGoodsId) => {
    this.onQuery();
  };

  //  下架
  @action public onSoldOut = (singleId?: number[]) => {
    const ids = singleId ? singleId : Array.from(this.gridModel.selectedIds);
    if (!ids.length) {
      message.warning('请选择商品');
      return;
    }

    Modal.confirm({
      title: '提示',
      content: '确定将商品下架？',
      okText: '确定下架',
      cancelText: '取消',

      // @ts-ignore
      onOk: () => this.putAwayOrSoldOut(ids, api.delisting),
    });
  };

  @action public putAwayOrSoldOut = async(ids: Array<string | number>, url: string): Promise<void> => {
    const res = await request<BaseData<any>>({
      url,
      method: 'post',
      data: { gmsGoodsId: ids },
    });
    this.onQuery();
    if (!res.data.failed) {
      message.success(res.data.operationName);
      return;
    }
    const params = {
      ...res.data,
      list: res.data.list,
    };

    this.batchReport(params);
  };

  //  渲染批量处理报告
  @action private batchReport = (params) => {
    renderModal(
      <BatchReport
        {...params}
        columns={[
          {
            title: '商品名称',
            dataIndex: 'dataParam',
          },
          {
            title: '失败原因',
            dataIndex: 'reason',
          },
        ]}
      />
    );
  };
}

export type IParentStore = ProductAnalysisStore;
